<template>
  <div>
    <product-dress-store ref='store' @success='getList' />
    <init-loading :isInitLoading.sync='isInitLoading'></init-loading>
    <div class='radius_bg' style='padding-bottom: 10px'>
      <el-form :model='searchCondition' inline size='mini' @submit.native.prevent>
        <el-form-item label='产品名称'>
          <el-input placeholder='产品名称' v-model='searchCondition.title' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item>
          <el-input placeholder='品牌' v-model='searchCondition.brand_name' @change='handleQuery' clearable
                    style='width: 200px;' />
        </el-form-item>
        <el-form-item>
          <ProductLeaderSelect ref='refProductLeader' :leader-id.sync='searchCondition.leader_id'
                               leader-type='product-dress'
                               @selected='handleQuery' v-if="userPermissions.indexOf('product_view_all') > -1" />
          <span v-else>{{ userInfo.nickname }} </span>
        </el-form-item>
        <el-form-item>
          <el-select @change='handleQuery' clearable placeholder="请选择是否标品" v-model="searchCondition.wares_standard_is">
            <el-option
                v-for="item in standardOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="modePrice" style="width: 100px" placeholder="">
            <el-option key="price" label="日常价" value="price"></el-option>
            <el-option key="lb_price" label="直播价" value="lb_price"></el-option>
          </el-select>
          <el-input placeholder='最低价' style="width: 100px" v-model='price[0]'
                    clearable/>
          ~
          <el-input placeholder='最高价' style="width: 100px" v-model='price[1]'
                    clearable/>
        </el-form-item>
        <el-form-item label='季节性'>
          <el-select @change='handleQuery' clearable v-model='searchCondition.used_season' placeholder='季节性'>
            <el-option label='春' value='春'></el-option>
            <el-option label='夏' value='夏'></el-option>
            <el-option label='秋' value='秋'></el-option>
            <el-option label='冬' value='冬'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click='reset'>重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <!--      卡片-->
      <div class=' radius_bg'>
        <el-row :gutter='10' class='mb8'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' @click='handleDressStore(false)'
                       v-if="userPermissions.indexOf('product_dress_create')>-1">添加
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button v-if="userPermissions.indexOf('product_dress_hand_over')>-1" type="warning" icon="el-icon-connection"
                       @click="toHandover">交接
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button icon='el-icon-upload' v-if="userPermissions.indexOf('product_dress_import')>-1"
                       @click='openImport=true'>
              导入
            </el-button>
          </el-col>
          <el-button icon='el-icon-setting'
                     v-show="mod==='Table'" @click='customColumnShow=true'>列设置
          </el-button>
          <el-col :span='1.5'>
            <export-btn :can-export='userPermissions.indexOf("product_dress_export")>-1' :lint-title='`导出表格`'
                        @export='downExcel' ref='refExportLink'></export-btn>
          </el-col>

          <el-col :span='1.5'>
            <el-button v-if='userPermissions.indexOf("product_dress_history_kol")>-1' icon='el-icon-plus'
                       @click='handleAddBtn("kol")'>历史选品会
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button v-if='userPermissions.indexOf("product_dress_history_live")>-1' icon='el-icon-plus'
                       @click='handleAddBtn("live")'>历史直播
            </el-button>
          </el-col>
          <el-col v-show="mod==='Table'" v-if='userPermissions.indexOf("product_transfer")>-1' :span='1.5'>
            <product-transfer :get-products='getSelectionProducts' @success='callbackProductTransfer'/>
          </el-col>
          <el-col v-show="mod==='Table'" v-if='userPermissions.indexOf("product_transfer")>-1' :span='1.5'>

            <status-dropdown @success="getList" :get-products='getSelectionProducts'></status-dropdown>
          </el-col>

          <el-col :span='4' style='float: right;text-align: right'>
            <el-radio-group v-model='mod'>
              <el-radio-button label='Calendar'>卡片(旧)</el-radio-button>
              <el-radio-button label='CalendarNew'>卡片(新)</el-radio-button>
              <el-radio-button label='Table'>列表</el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
        <dress-old-card v-if="mod==='Calendar'" @handleDressStore='handleDressStore' @handleDelete='handleDelete'
                        @handleDetails='handleDetails' @handleJoinLive='handleJoinLive' :dataList='dataList'
                        :loading='loading'></dress-old-card>
        <dress-card v-show="mod==='CalendarNew'" @handleDressStore='handleDressStore' @handleDelete='handleDelete'
                    @handleDetails='handleDetails' @handleJoinLive='handleJoinLive' :dataList='dataList'
                    :loading='loading'></dress-card>

        <div class='default-table radius_bg' v-if="mod==='Table'">
          <el-table ref='dress' v-loading='tableLoading' :data='dataList' style='width: 100%'>
            <el-table-column type='selection' width='55' />
            <el-table-column align='center' label='序号' type='index' width='50' fixed='left' />
            <el-table-column min-width='360' label='基本信息' fixed='left'>
              <template v-slot='{row:{cover_url,spec_name,title,updated_at,brand_name,platform_name,wares_standard_is},row}'>
                <div class='table-column__basic'>
                  <div style='width: 76px; height: 76px;overflow: hidden;display: inline-block'>
                    <el-image :fit="'contain'" style='width: 100%;height: 100%' :src='cover_url'
                              :preview-src-list='[cover_url]' />
                  </div>
                  <div class='table-column__basic-right' style='display:inline-block;'>
                    <p class='title'>
                      <span v-if='brand_name'>{{ brand_name }}</span>
                      {{ title }} <el-tag v-if="Number(wares_standard_is) === 1 " style="padding: 0 4px">标品
                    </el-tag>
                    </p>
                    <p class='time'>尺码颜色：<b>{{ spec_name }}</b></p>
                    <p class='time'>季节性：<b>{{ row?.used_season }}</b></p>
                    <p class='time'>可播平台：<b>{{ String(platform_name) }}</b></p>
                    <p class='time'>更新时间：<b>{{ updated_at|parseTime('{y}/{m}/{d} {h}:{i}') }}</b></p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop='samples' label='寄样明细' min-width='80' align='center' />
            <el-table-column prop='remark' label='备注' min-width='60' align='center' />
            <el-table-column min-width='130' label='预售补货'>
              <template v-slot='{row:{wares_prep}}'>
                <div v-if='typeof wares_prep==="string"'>
                  <div v-for='(tmp,tmp_i) in wares_prep.split(" ； ")' :key='tmp_i'>
                    {{ tmp || '' }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width='200' label='现货库存'>
              <template v-slot='{row:{wares_exist}}'>
                <div v-if='typeof wares_exist==="string"'>
                  <div v-for='(tmp,tmp_i) in wares_exist.split(" ； ")' :key='tmp_i'>
                    {{ tmp || '' }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop='delivery_cycle' label='发货时效' min-width='80' align='center'
                             show-overflow-tooltip />
            <el-table-column min-width='130' label='价格'>
              <template v-slot='{row:{price,lb_price,commission,support_situation}}'>
                <p>日常价：<b>{{ price || '-' }}</b></p>
                <p>直播价：<b>{{ lb_price || '-' }}</b></p>
                <p>佣金：<b>{{ commission || '-' }}</b></p>
                <p>保价情况：<b>{{ support_situation || '-' }}</b></p>
              </template>
            </el-table-column>
            <el-table-column prop='leader_name' label='负责人' min-width='80' align='center' show-overflow-tooltip />
            <el-table-column min-width='100' label='操作' align='center' fixed='right'>
              <template v-slot='{row}'>
                <el-button type='text' icon='el-icon-edit' v-if="userPermissions.indexOf('product_dress_edit')>-1"
                           @click='handleDressStore(row.id)'>修改
                </el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row)'
                           v-if="userPermissions.indexOf('product_dress_delete')>-1">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <pagination :hidden='total===0'
                    :background='true'
                    :total.sync='total'
                    :autoScroll='true'
                    :page.sync='pageData.current_page'
                    :limit.sync='pageData.page_size'
                    @pagination='getList' />
        <!--        注意事项-->
        <Tips :data='tipsList'></Tips>
        <div v-if='userPermissions.indexOf("product_dress_log")>-1'>
          <el-divider></el-divider>
          <el-link type='primary' @click='showLog=!showLog' style='margin: 10px'>日志
            <span :class='[!showLog?"el-icon-arrow-down":"el-icon-arrow-up"]'></span>
          </el-link>
          <!--        <el-divider content-position='left'>-->
          <!--     -->
          <!--        </el-divider>-->
          <!--        日志表格-->
          <ProductDressLog v-if='showLog' />
        </div>
      </div>


    </div>


    <!--    添加组件-->
    <add-dress ref='addDress' @handelStore='handelStore' :visible.sync='open' :id='currentId'
               @close='getList'></add-dress>
    <!--    列设置-->
    <MColumnSetting :visible.sync='customColumnShow' ref='columnSetting' @userChange='columnSettingChange'
                    :column='userColumns' :table-name='tableName' @back='columnSettingBack'
                    @save='columnSettingSave'></MColumnSetting>
    <!--    右键菜单-->
    <!--    <ProductLog :info-id='currId' :visible.sync='openLog'/>-->
    <exportDialog :file-name='`服饰品牌库`' :column='userColumns'
                  :searchCondition='searchCondition' :listApi="'getProductDressList'" :visible.sync='downloadLoading'
                  @close='downloadLoading=false'></exportDialog>
    <JoinLive :productId='productId' :visible.sync='joinliveShow'></JoinLive>
    <!--    历史排期-->
    <!--    historicalDialog-->
    <HistoricalPage :historicalType='HistoricalType' :visible.sync='historicalDialog'
                    @close='getList'></HistoricalPage>
    <!--    导入标品-->
    <ImportProductDress :visible.sync='openImport' @imported='handleQuery' />
    <dress-details :visible.sync='openDetails' :rowDetails='rowDetails' />

  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ProductCreateEdit from '@/pages/lb/product/ProductCreateEdit'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import {mapGetters} from 'vuex'
import ProductLog from '@/pages/lb/product/ProductLog'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect'
import ExportPpt from '@/pages/lb/components/ExportPpt'
import ProductImage from '@/pages/lb/product/ProductImage'
import ExportProductPpt from '@/pages/lb/components/ExportProductPpt'
import _, {omitBy} from 'lodash'
import AddDress from '@/pages/lb/dress/AddDress.vue'
import MSetTable from '@/components/common/MSetTable'
import MColumnSetting from '@/components/common/MColumnSetting'
import exportDialog from '@/pages/lb/dress/exportDialog.vue'
import ProductDressLog from '@/pages/lb/dress/components/ProductDressLog'
import JoinLive from '@/pages/lb/dress/JoinLive.vue'
import HistoricalPage from '@/pages/lb/dress/HistoricalPage.vue'
import ImportProductDress from '@/pages/lb/dress/components/ImportProductDress'
import ProductDressStore from '@/pages/lb/dress/store.vue'
import DressCard from '@/pages/lb/dress/DressCard.vue'
import DressOldCard from '@/pages/lb/dress/DressOldCard.vue'
import DressDetails from './DressDetails'
import ProductTransfer from '@/pages/lb/product/component/transfer.vue'
import StatusDropdown from '@/pages/lb/dress/components/StatusDropdown.vue'

export default {
  name: 'ProductLibrary',
  components: {
    StatusDropdown,
    ProductTransfer,
    ProductDressStore,
    ImportProductDress,
    ProductDressLog,
    AddDress,
    ExportProductPpt,
    ExportPpt,
    ProductLeaderSelect,
    ProductLog,
    CategoryCascader, ProductCreateEdit, ExportBtn, PageHeaderLayout,
    ProductImage,
    MSetTable, MColumnSetting, exportDialog, JoinLive, HistoricalPage, DressCard,
    DressDetails,
    DressOldCard
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      openDetails: false,
      rowDetails: {},
      tipsList: [
        '1、表格内右键菜单，可进行各项操作（修改、复制、删除、日志）'
      ],
      loadingBrandStatus: false,
      brandOptions: [],
      OptionsLeader: [],
      searchCondition: { leader_id: null, category_id: null, title: null, unify: null,wares_standard_is:null },
      dataList: [],
      total: 0,
      orderSort: { id: 'desc' },
      pageData: { page_size: 10, current_page: 1 },
      loading: false,
      exportLoading: false,
      userColumns: [
        // { hide: false, prop: 'id', label: '序号', width: 80, sortable: false, fixed: false ,type:'index'},
        { hide: true, prop: 'id', label: 'ID', width: 80, sortable: false, fixed: false },
        { hide: false, prop: 'cover_url', label: '产品图', width: 60, sortable: false, fixed: false, type: 'image' },
        { hide: false, prop: 'brand_name', label: '品牌', width: 60, sortable: false, fixed: false },
        { hide: false, prop: 'title', label: '产品', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'spec_name', label: '尺码/颜色', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'samples', label: '寄样明细', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'wares_prep', label: '预售补货', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'wares_exist', label: '现货库存', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'delivery_cycle', label: '发货时效', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'price', label: '日常价', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'lb_price', label: '直播价', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'commission', label: '佣金', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'support_situation', label: '保价情况', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'leader_name', label: '负责人', width: 200, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '最后更新时间', width: 200, sortable: false, fixed: false }
      ],
      columns: [],
      open: false,
      currentId: null,
      openLog: false,//日志模态框
      currId: null,
      copyRow: null,
      currentRow: {},
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      //导入相关
      openImport: false,
      mod: 'Calendar',
      andMore: false,
      moreArray: [],
      multipleSelection: [],
      showPreview: false,
      pptData: {},
      isInitLoading: true,
      formatDate: {},
      custom_type: 'add',
      formStore: {
        name: '',
        spec_name: '',
        price: '',
        lb_price: '',
        gift: '',
        online_cmn: '',
        support_value: '',
        exclusive: ''

      },
      uploadFileList: [],
      tableLoading: false,
      customColumnShow: false,
      tableName: 'product_dress',
      downloadLoading: false,
      showLog: false,//日志展示
      joinliveShow: false,
      productId: '',
      HistoricalType: 'kol',
      historicalDialog: false,
      standardOption: [
        { label: '标品', value: 1, color_value: '#D8FCC0' },
        { label: '非标品', value: 2, color_value: '#E9EDFA' }
      ],
      modePrice: 'price',
      price: []
    }
  },
  created() {
    this.getLeaders()
    this.getList()
    this.getDefaultColumns()
  },
  methods: {
    getLeaders: async function() {
      this.OptionsLeader = await this.$api.productCheckLeaders()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectedLeader() {
      // this.searchCondition.leader_id=item.value
      this.handleQuery()
    },
    async getList() {
      this.loading = true
      this.currentId = null
      this.dataList = []
      this.moreArray = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData, { order: this.orderSort })
      let { list, pages } = await this.$api.getProductDressList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
      this.loading = false
      this.isInitLoading = false
    },
    async getBrand(force) {
      this.loadingBrandStatus = true
      if (this.brandOptions.length === 0 || force) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
        this.loadingBrandStatus = false
      }
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async asyncExportExcel() {
      this.exportLoading = true
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { order: this.orderSort })
      searchCondition.is_tpl = 1
      let { task_id } = await this.$api.asyncExportProduct(searchCondition)
      // this.$notify.warning(task_id)
      let msg = '<strong>点击这里 <a href=\'/download_file\' target=\'_blank\'>下载</a> 生成的文件:' + task_id + '</strong>'
      this.$notify({
        title: '导出文件生成中',
        dangerouslyUseHTMLString: true,
        message: msg,
        type: 'success',
        duration: 0
      })
      this.exportLoading = false
    },
    async exportExcel() {
      this.downloadLoading = true
      // try {
      //   let searchCondition = this.handleSearchCondition()
      //   Object.assign(searchCondition, { order: this.orderSort })
      //   let name = `服饰产品库`
      //   let response = await this.$api.exportProductDress(searchCondition)
      //
      //   this.$refs.refExportLink.exportSaveXlsx(response, name)
      // } catch (e) {
      //   this.$message.warning('导出异常，请联系管理员')
      //   // alert(JSON.stringify(e))
      // }
    },
    async downExcel() {
      try {
        let searchCondition = this.handleSearchCondition()
        Object.assign(searchCondition, { order: this.orderSort })
        let name = `服饰产品库`
        let response = await this.$api.exportProductDress(searchCondition)

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    handleSearchCondition() {
      let params = { ...{}, ...this.searchCondition }
      params = omitBy(params, (value) => value === undefined || value === null || value === '')
      // 线上佣金
      if (this.price[1] !== undefined || this.price[1] != null) {
        this.price[0] = 0
      }
      this.price=this.price.map((item)=>{return Number(item)})

      params[this.modePrice] = this.price
      return params
    },
    handleEdit(row) {
      this.currentId = row.id
      this.open = true
    },
    handleDressStore(id) {
      this.$refs.store.show(id)
    },
    async delInfo(id) {
      let info = await this.$api.delProduct(id)
      if (info) {
        this.msgSuccess('删除成功')
        await this.getList()
      }
    },
    handleDelete(row) {
      let _t = this
      this.$confirm(`是否确认删除产品【${row.title}】?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let _index = _.findIndex(this.dataList, function(o) {
          return _t.formStore.id == o.id
        })
        this.dataList.splice(_index, 1)
        if (row.id) {
          let id = await this.$api.deleteProductDressInfo(row.id)
          if (id) {
            this.$message.success('删除成功')
            await this.getList()
          }
        }
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },


    //新增-修改保存按钮
    handelStore(val) {
      let _t = this
      this.$refs.addDress.validate().then(() => {
        if (val.cover_url) {
          if (this.custom_type === 'add') {
            //这里是新增的逻辑
            this.dataList.push(val)
            this.open = false
            this.$message.success('新增成功')
          } else {
            //这里是修改的逻辑
            let _index = _.findIndex(this.dataList, function(o) {
              return _t.formStore.id == o.id
            })
            this.dataList[_index] = val
            this.open = false
            this.$message.success('修改成功')
            this.formStore = {}

          }
        } else {
          this.$message.error('产品图必填')
        }
      }).catch(() => {
        this.$message.error('请检查必填信息')
      })
    },
    //重置
    reset() {
      this.searchCondition = {}
      this.getList()
    },
    getDefaultColumns() {
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.columns = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        this.columns = [...this.userColumns]
      }
    },
    columnSettingChange(userColumn) {
      //设置列-修改表显示
      // this.customColumnShow=false
      this.columns = [...userColumn]
      this.isColSet = true
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingBack() {
      this.customColumnShow = false
      this.isColSet = false
      this.getDefaultColumns()
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingSave() {
      this.customColumnShow = false
      this.isColSet = true
    },
    handleJoinLive(row) {
      this.joinliveShow = true
      this.productId = row.id
    },
    handleAddBtn(type) {
      this.HistoricalType = type
      this.historicalDialog = true
    },
    handleDetails(row) {
      this.rowDetails = row
      this.openDetails = true
    },
    getSelectionProducts: function() {
      return this.$refs.dress.selection
    },
    callbackProductTransfer: function() {
      this.$refs.dress.clearSelection()
      this.handleQuery()
    },
    toHandover: function() {
      const routeUrl = this.$router.resolve({
        name: 'productHandover',
        query: {type: 'dress'}
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/pages/lb/style/main.scss";

.card_content {
  > span {
    display: block;
    font-size: 14px;
    margin: 4px 0;
    color: #606266;

    > a {
      color: #c92b60;
    }
  }

  > a {
    font-size: 13px;
    margin: 2px 0;

  }
}

.box_content {
  width: 100%;
  height: auto;
  min-height: 800px;
  //border: 1px solid red;
  //border: 1px solid red;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  justify-content: center;
  //flex-direction: column;

  > .card {
    cursor: pointer;
    width: calc((100% / 5) - 50px);
    min-width: 300px;
    margin: 10px auto;
    //max-height: 280px;
    float: left;
    padding-bottom: 10px;
    max-height: 500px;
  }
}

@media(max-width: 1700px) {
  .box_content {
    width: 100%;
    //min-width: 1080px;
    //overflow: scroll;
    > .card {
      width: calc((100% / 5) - 26px);
      min-width: 180px;
      margin: 10px auto;
    }
  }
}
</style>

<style lang='scss'>
@import "@/pages/lb/style/main.scss";

.productDress_main-inner {
  //height: calc(100vh - 90px);

  //.page-content {
  //  min-height: unset !important;
  //  height: calc(100% - 38px);
  //
  //  .productDressAudit_main-ape {
  //    height: 100%;
  //
  //    .product-dress-audit-container {
  //      height: calc(100% - 62px);
  //      background: #fff;
  //      padding: 12px;
  //    }
  //  }
  //}


  &__content {
    //height: 100%;
    //overflow: hidden;

    p {
      margin: unset;
    }
  }

  .table-column__basic {
    //display: flex;
    width: 100%;
    //justify-content: space-between;
    .el-image {
      width: 76px;
      //border: 1px solid red;
      border-radius: 6px;
      display: block;
    }

    &-right {
      max-width: calc(100% - 100px);
      margin-left: 10px;

      .title {
        color: #45474d;
        font-weight: 700;
        line-height: 1.4;
        font-size: 13px;
        margin-bottom: 4px;
        margin-top: 2px;

        span {
          font-size: 12px;
          background-color: $--status-lock-bg;
          //border: 1px solid #feb2c8;
          border-radius: 13px;
          margin-right: 6px;
          padding: 3px 10px;
          color: $--color-text-regular;
        }
      }

      .time {

        //width: auto;
        margin: 10px 0;
        font-size: 12px;
        color: #8a8e99;
        background-color: $--background-color-extralight;
        border-radius: 4px;
        padding: 3px;

        > * {
          padding: 3px
        }
      }
    }
  }

  .el-table__cell {
    .cell {
      line-height: 1.2;
    }
  }
}

</style>
