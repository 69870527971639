import { render, staticRenderFns } from "./ImportProductDress.vue?vue&type=template&id=9360b006&scoped=true"
import script from "./ImportProductDress.vue?vue&type=script&lang=js"
export * from "./ImportProductDress.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9360b006",
  null
  
)

export default component.exports